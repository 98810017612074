.backdrop {
  position: fixed; 
  inset: 0;  
  overflow: auto;
  index: -10;
  background: rgba(0,0,0,0.5);
}

.modal {
  color: rgba(253,0,0,0.29735644257703087);
}